/**
 * 
 * @authors Wangfeiran (wangfeiran@qiyi.com)
 * @date    2019-10-18 16:15:28
 * @version 1.0.0
 * @desc 路由正反解析
 */
import pageNames from "@/config/page_name"

function getByName(name) {
  let router = pageNames.find(val => val.name == name);
  if(router != null) {
    return router;
  }
  return {
		name,
		router: name,
  }
}

function getByPath(path) {
  let router = pageNames.find(val => val.router == path);
  if(router != null) {
    return router;
  }
  return {
		name: path,
		router: path,
  }
}

const router = {
  getRouter(name) {
    return getByName(name).router;

  },
  getKey(name) {
    return getByName(name).lang_key;
  },
  getName(path) {
    return getByPath(path).name;
  },
}

export default router;
